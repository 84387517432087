import React from "react"
import { IndexPageTemplate } from "~templates/pages/Index"

const activeDocMeta = {
    lang: "zh-cn",
    alternateLanguages: [
        { type: "home", lang: "en-gb", url: "/en" },
        { type: "home", lang: "de-de", url: "/de" },
    ],
}

const IndexPage = () => <IndexPageTemplate activeDocMeta={activeDocMeta} />

export default IndexPage
